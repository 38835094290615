import { Field, Form, Formik } from "formik";
import { lotterySchema } from "../../yupSchema/lotterySchema";
import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { CheckIsNull, toAbsoluteUrl } from "../../../../utils/CommonHelper";
import SweetAlert2 from "react-sweetalert2";
import { LotteryCompanyConst } from "../../../../constants/LotteryConst";
import { LotteryPayoutDialog } from "../lotteryPayout/LotteryPayoutDialog";

export const LotteryBetForm = ({ saveValues, lotteryType }) => {
  const intl = useIntl();
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [activeInput, setActiveInput] = useState("luckyNumber");
  const [luckyNumber, setLuckyNumber] = useState("");
  const [bNumber, setBNumber] = useState("");
  const [sNumber, setSNumber] = useState("");
  const [aNumber, setANumber] = useState("");
  const [isBoxActive, setIsBoxActive] = useState(false);
  const [isIboxActive, setIsIboxActive] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const [isLotteryPayoutModalVisible, setLotteryPayoutModalVisible] =
    useState(false);
  const [currentQues, setCurrentQues] = useState("");
  const inputRefs = {
    luckyNumber: useRef(null),
    bNumber: useRef(null),
    sNumber: useRef(null),
    aNumber: useRef(null),
  };
  useEffect(() => {
    setSelectedCompany([]);
  }, [lotteryType]);

  const handleFocus = (e) => {
    e.target.select();
  };

  const PermAlone = (str) => {
    let arr = str.split("");
    let reg = /(.)\1+/; //regexp for repeating characters
    let perms = 0;
    function swap(indexFrom, indexTo, arr) {
      if (indexTo === arr.length - 1) {
        let enterStr = arr.join("");
        if (!reg.test(enterStr)) perms++;
      } else {
        let newArr = []; //newArr(copy of arr) is needed so that not to modify the original one, as next calls of swap() need it
        for (let i = 0; i < arr.length; i++) {
          newArr.push(arr[i]);
        }
        let temp = newArr[indexFrom];
        newArr[indexFrom] = newArr[indexTo];
        newArr[indexTo] = temp;

        for (let i = indexTo + 1; i < arr.length; i++) {
          swap(i, indexTo + 1, newArr);
        }
      }
    }
    //start recursion
    for (let i = 0; i < arr.length; i++) {
      swap(i, 0, arr);
    }

    return perms;
  };

  const GetNumDiffer = (numDiffers) => {
    let countDiffer = 0;
    for (const numDiffer in numDiffers) {
      if (numDiffers.hasOwnProperty(numDiffer)) {
        ++countDiffer;
      }
    }
    return countDiffer;
  };

  const BoxMultiply = (value) => {
    const arr = !CheckIsNull(value) ? value.toString().split("") : [];
    let numDiffers = {};
    let totalBox = 0;

    for (let i = 0; i < arr.length; i++) {
      numDiffers[arr[i]] = 1 + (numDiffers[arr[i]] || 0);
    }
    let countDiffer = GetNumDiffer(numDiffers);
    if (countDiffer === 2) {
      totalBox = PermAlone(value.toString());
      if (totalBox === 0) totalBox = 4;
      if (totalBox === 8) totalBox = 6;
    } else {
      if (countDiffer === 1) totalBox = 1;
      else if (countDiffer === 3) totalBox = 12;
      else totalBox = 24;
    }

    return totalBox;
  };

  const handleKeyClick = (value) => {
    if (activeInput === "luckyNumber") {
      const input = inputRefs.luckyNumber.current;
      const { selectionStart, selectionEnd } = input;

      if (selectionStart !== selectionEnd) {
        // Replace the selected text with the new value
        const newLuckyNumber =
          luckyNumber.slice(0, selectionStart) +
          value +
          luckyNumber.slice(selectionEnd);
        setLuckyNumber(newLuckyNumber);
      } else if (luckyNumber.length < 4) {
        // Append the value if no text is selected and length is less than 4
        setLuckyNumber((prev) => prev + value);
      }

      // Shift focus to the next input when the luckyNumber length reaches 4
      if (luckyNumber.length === 3) {
        setActiveInput("bNumber");
        inputRefs.bNumber.current.focus();
      }
    } else if (activeInput === "bNumber") {
      const input = inputRefs.bNumber.current;
      const { selectionStart, selectionEnd } = input;

      if (selectionStart !== selectionEnd) {
        const newBNumber =
          bNumber.slice(0, selectionStart) +
          value +
          bNumber.slice(selectionEnd);
        setBNumber(newBNumber);
      } else {
        setBNumber((prev) => prev + value);
      }
    } else if (activeInput === "sNumber") {
      const input = inputRefs.sNumber.current;
      const { selectionStart, selectionEnd } = input;

      if (selectionStart !== selectionEnd) {
        const newSNumber =
          sNumber.slice(0, selectionStart) +
          value +
          sNumber.slice(selectionEnd);
        setSNumber(newSNumber);
      } else {
        setSNumber((prev) => prev + value);
      }
    } else if (activeInput === "aNumber") {
      const input = inputRefs.aNumber.current;
      const { selectionStart, selectionEnd } = input;

      if (selectionStart !== selectionEnd) {
        const newANumber =
          aNumber.slice(0, selectionStart) +
          value +
          aNumber.slice(selectionEnd);
        setANumber(newANumber);
      } else {
        setANumber((prev) => prev + value);
      }
    }
  };

  const handleBackspace = () => {
    if (activeInput === "luckyNumber") {
      setLuckyNumber("");
    } else if (activeInput === "bNumber") {
      setBNumber("");
    } else if (activeInput === "sNumber") {
      setSNumber("");
    } else if (activeInput === "aNumber") {
      setANumber("");
    }
  };

  const handleInputClick = (inputName) => {
    setActiveInput(inputName);
    inputRefs[inputName].current.focus();
  };

  const handleToggleBox = () => {
    setIsBoxActive((prev) => !prev);
    setIsIboxActive(false);
  };

  const handleToggleIbox = () => {
    setIsIboxActive((prev) => !prev);
    setIsBoxActive(false);
  };
  const handleAddList = () => {
    if (selectedCompany.length > 0) {
      if (luckyNumber != "" && luckyNumber.length > 3) {
        if (
          (bNumber !== "" && bNumber != 0) ||
          (sNumber !== "" && sNumber != 0) ||
          (aNumber !== "" && aNumber != 0)
        ) {
          if (bNumber >= 0.1 || sNumber >= 0.1 || aNumber >= 0.1) {
            const sum =
              parseFloat(bNumber ? bNumber : 0) +
              parseFloat(sNumber ? sNumber : 0) +
              parseFloat(aNumber ? aNumber : 0);

            const subTotal =
              sum *
              (isBoxActive ? BoxMultiply(luckyNumber) : 1) *
              selectedCompany.length;
            const companyList = selectedCompany
              .map((company) => company.lotteryKeyCode)
              .join("");

            const longCode = {
              key: Date.now(),
              companyList: selectedCompany,
              number: luckyNumber,
              big: bNumber,
              small: sNumber,
              fourA: aNumber,
              betType: isBoxActive ? "Box" : isIboxActive ? "IBox" : "",
              subTotal,
              code: `#${companyList}|${
                isBoxActive ? "*" : isIboxActive ? "**" : ""
              }${luckyNumber}#${bNumber}#${sNumber}#${aNumber}`,
            };

            saveValues(longCode);

            setLuckyNumber("");
            setActiveInput("luckyNumber");
            setIsBoxActive(false);
            setIsIboxActive(false);
          } else {
            setSwalProps({
              show: true,
              icon: "error",
              title: intl.formatMessage({ id: "fail" }),
              text: intl.formatMessage({ id: "minBet" }),
            });
          }
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "fail" }),
            text: intl.formatMessage({ id: "inputBAS" }),
          });
        }
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "fail" }),
          text: intl.formatMessage({ id: "inputNumber" }),
        });
      }
    } else {
      setSwalProps({
        show: true,
        icon: "error",
        title: intl.formatMessage({ id: "fail" }),
        text: intl.formatMessage({ id: "selectCompany" }),
      });
    }
  };

  return (
    <>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <LotteryPayoutDialog
        setSwalProps={setSwalProps}
        currentQues={currentQues}
        isVisible={isLotteryPayoutModalVisible}
        onClose={() => {
          setLotteryPayoutModalVisible(false);
        }}
      />
      <Formik
        initialValues={{
          number: "",
          big: 0,
          small: 0,
          fourA: 0,
        }}
        validationSchema={lotterySchema(intl)}
        enableReinitialize={true}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form>
            <div className="w-100">
              <div
                className="d-flex flex-column w-100 mb-2"
                style={{ gap: "10px" }}
              >
                <div className="d-flex flex-wrap align-items-center justify-content-between me-2">
                  {LotteryCompanyConst.filter(
                    (lotteryCompany) =>
                      !["H", "E"].includes(lotteryCompany.lotteryCode)
                  ).map((lotteryCompany, index) => {
                    const isSpecialSelected = selectedCompany.some((company) =>
                      ["H", "E"].includes(company.lotteryCode)
                    );

                    const isDisabled = isSpecialSelected;

                    return (
                      <div
                        className={`d-flex flex-column align-items-center justify-content-center py-2 px-1 rounded cursor-pointer ${
                          selectedCompany.some(
                            (company) =>
                              company.lotteryCode === lotteryCompany.lotteryCode
                          )
                            ? "text-dark"
                            : "text-muted"
                        }`}
                        key={`regular-${index}`}
                        style={{
                          width: "35px",
                          userSelect: "none",
                          backgroundColor: selectedCompany.some(
                            (company) =>
                              company.lotteryCode === lotteryCompany.lotteryCode
                          )
                            ? "rgb(245, 190, 9)"
                            : "rgba(255, 255, 255, 0.1)",
                          opacity: isDisabled ? 0.3 : 1,
                          pointerEvents: isDisabled ? "none" : "auto",
                        }}
                        onClick={() => {
                          if (isDisabled) return;

                          if (
                            selectedCompany.some(
                              (company) =>
                                company.lotteryCode ===
                                lotteryCompany.lotteryCode
                            )
                          ) {
                            setSelectedCompany(
                              selectedCompany.filter(
                                (company) =>
                                  company.lotteryCode !==
                                  lotteryCompany.lotteryCode
                              )
                            );
                          } else {
                            setSelectedCompany([
                              ...selectedCompany,
                              lotteryCompany,
                            ]);
                          }
                        }}
                      >
                        <img
                          src={toAbsoluteUrl(lotteryCompany.lotteryUrl)}
                          alt={lotteryCompany.lotteryCode}
                          className="w-100"
                        />
                      </div>
                    );
                  })}
                </div>

                {/* Special items (2nd line) */}
                <div className="d-flex flex-wrap align-items-center justify-content-between me-2">
                  {LotteryCompanyConst.filter((lotteryCompany) =>
                    ["H", "E"].includes(lotteryCompany.lotteryCode)
                  ).map((lotteryCompany, index) => {
                    const isOtherSelected = selectedCompany.some(
                      (company) =>
                        !["H", "E"].includes(company.lotteryCode) &&
                        company.lotteryCode !== lotteryCompany.lotteryCode
                    );

                    const isDisabled = isOtherSelected;

                    return (
                      <div
                        className={`d-flex flex-column align-items-center justify-content-center py-2 px-1 rounded cursor-pointer ${
                          selectedCompany.some(
                            (company) =>
                              company.lotteryCode === lotteryCompany.lotteryCode
                          )
                            ? "text-dark"
                            : "text-muted"
                        }`}
                        key={`special-${index}`}
                        style={{
                          width: "35px",
                          userSelect: "none",
                          backgroundColor: selectedCompany.some(
                            (company) =>
                              company.lotteryCode === lotteryCompany.lotteryCode
                          )
                            ? "rgb(245, 190, 9)"
                            : "rgba(255, 255, 255, 0.1)",
                          opacity: isDisabled ? 0.3 : 1,
                          pointerEvents: isDisabled ? "none" : "auto",
                        }}
                        onClick={() => {
                          if (isDisabled) return;

                          if (
                            selectedCompany.some(
                              (company) =>
                                company.lotteryCode ===
                                lotteryCompany.lotteryCode
                            )
                          ) {
                            setSelectedCompany(
                              selectedCompany.filter(
                                (company) =>
                                  company.lotteryCode !==
                                  lotteryCompany.lotteryCode
                              )
                            );
                          } else {
                            setSelectedCompany([
                              ...selectedCompany,
                              lotteryCompany,
                            ]);
                          }
                        }}
                      >
                        <img
                          src={toAbsoluteUrl(lotteryCompany.lotteryUrl)}
                          alt={lotteryCompany.lotteryCode}
                          className="w-100"
                        />
                      </div>
                    );
                  })}
                  {[...Array(5)].map((_, index) => (
                    <div
                      key={`placeholder-${index}`}
                      style={{
                        width: "35px",
                        height: "35px", // Ensure the placeholders have the same height
                        backgroundColor: "transparent",
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="col-12 ">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <label className="me-2 text-nowrap">
                    {intl.formatMessage({ id: "luckyNumber" })}:
                  </label>
                  <input
                    className="mx-2"
                    value={luckyNumber}
                    readOnly
                    ref={inputRefs.luckyNumber}
                    onFocus={handleFocus}
                    style={{
                      width: "100%",
                      border:
                        activeInput === "luckyNumber"
                          ? "2px solid rgb(245, 190, 9)"
                          : "none",
                      boxShadow:
                        activeInput === "luckyNumber"
                          ? "0 0 5px rgb(245, 190, 9)"
                          : "none",
                      borderRadius: "5px",
                    }}
                    onClick={() => handleInputClick("luckyNumber")}
                  />
                </div>
                <div
                  className="d-flex align-items-center justify-content-between mb-2"
                  style={{ gap: "0.5rem" }}
                >
                  <div className="flex-fill">
                    <div className="d-flex align-items-center">
                      <label className="me-1">
                        {intl.formatMessage({ id: "b" })}:
                      </label>
                      <i
                        className="bi bi-question-circle"
                        style={{ color: "#f5be09" }}
                        onClick={() => {
                          setLotteryPayoutModalVisible(true);
                          setCurrentQues("b");
                        }}
                      />
                      <input
                        value={bNumber}
                        readOnly
                        ref={inputRefs.bNumber}
                        onFocus={handleFocus}
                        className="mx-2"
                        style={{
                          width: "100%",
                          border:
                            activeInput === "bNumber"
                              ? "2px solid rgb(245, 190, 9)"
                              : "none",
                          boxShadow:
                            activeInput === "bNumber"
                              ? "0 0 5px rgb(245, 190, 9)"
                              : "none",
                          borderRadius: "5px",
                        }}
                        onClick={() => handleInputClick("bNumber")}
                      />
                    </div>
                  </div>
                  <div className="flex-fill">
                    <div className="d-flex align-items-center">
                      <label className="me-1">
                        {intl.formatMessage({ id: "s" })}:
                      </label>
                      <i
                        className="bi bi-question-circle"
                        style={{ color: "#f5be09" }}
                        onClick={() => {
                          setLotteryPayoutModalVisible(true);
                          setCurrentQues("s");
                        }}
                      />
                      <input
                        value={sNumber}
                        readOnly
                        ref={inputRefs.sNumber}
                        onFocus={handleFocus}
                        className="mx-2"
                        style={{
                          width: "100%",
                          border:
                            activeInput === "sNumber"
                              ? "2px solid rgb(245, 190, 9)"
                              : "none",
                          boxShadow:
                            activeInput === "sNumber"
                              ? "0 0 5px rgb(245, 190, 9)"
                              : "none",
                          borderRadius: "5px",
                        }}
                        onClick={() => handleInputClick("sNumber")}
                      />
                    </div>
                  </div>
                  <div className="flex-fill">
                    <div className="d-flex align-items-center">
                      <label className="me-1">
                        {4 + intl.formatMessage({ id: "a" })}:
                      </label>
                      <i
                        className="bi bi-question-circle"
                        style={{ color: "#f5be09" }}
                        onClick={() => {
                          setLotteryPayoutModalVisible(true);
                          setCurrentQues("4a");
                        }}
                      />
                      <input
                        value={aNumber}
                        readOnly
                        ref={inputRefs.aNumber}
                        onFocus={handleFocus}
                        className="mx-2"
                        style={{
                          width: "100%",
                          border:
                            activeInput === "aNumber"
                              ? "2px solid rgb(245, 190, 9)"
                              : "none",
                          boxShadow:
                            activeInput === "aNumber"
                              ? "0 0 5px rgb(245, 190, 9)"
                              : "none",
                          borderRadius: "5px",
                        }}
                        onClick={() => handleInputClick("aNumber")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                position: "fixed",
                bottom: 0,
                right: 0,
                width: "100%",
              }}
            >
              <table
                className="keyboard-number"
                style={{
                  width: "100%",
                  textAlign: "center",
                  background: "white",
                  color: "black",
                  borderCollapse: "collapse",
                }}
              >
                <tbody>
                  <tr style={{ height: "4vh" }}>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                      }}
                      onClick={() => handleKeyClick("1")}
                    >
                      1
                    </td>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                      }}
                      onClick={() => handleKeyClick("2")}
                    >
                      2
                    </td>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                      }}
                      onClick={() => handleKeyClick("3")}
                    >
                      3
                    </td>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                      }}
                      onClick={handleBackspace}
                    >
                      <i className="bi bi-backspace-fill" />
                    </td>
                  </tr>
                  <tr style={{ height: "4vh" }}>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                      }}
                      onClick={() => handleKeyClick("4")}
                    >
                      4
                    </td>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                      }}
                      onClick={() => handleKeyClick("5")}
                    >
                      5
                    </td>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                      }}
                      onClick={() => handleKeyClick("6")}
                    >
                      6
                    </td>
                    {activeInput === "luckyNumber" ? (
                      <td
                        style={{
                          border: "2px solid grey",
                          width: "25%",
                          height: "40px",
                        }}
                      ></td>
                    ) : (
                      <td
                        style={{
                          border: "2px solid grey",
                          width: "25%",
                          height: "40px",
                        }}
                        onClick={() => handleKeyClick(".")}
                      >
                        .
                      </td>
                    )}
                  </tr>
                  <tr style={{ height: "4vh" }}>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                      }}
                      onClick={() => handleKeyClick("7")}
                    >
                      7
                    </td>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                      }}
                      onClick={() => handleKeyClick("8")}
                    >
                      8
                    </td>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                      }}
                      onClick={() => handleKeyClick("9")}
                    >
                      9
                    </td>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                      }}
                      onClick={handleAddList}
                    >
                      <i className="bi bi-plus-circle" />
                    </td>
                  </tr>
                  <tr style={{ height: "4vh" }}>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        background: isBoxActive ? "green" : "",
                        color: isBoxActive ? "white" : "",
                        height: "40px",
                      }}
                      onClick={handleToggleBox}
                    >
                      {intl.formatMessage({ id: "box" })}
                    </td>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                      }}
                      onClick={() => handleKeyClick("0")}
                    >
                      0
                    </td>
                    <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        height: "40px",
                        background: isIboxActive ? "green" : "",
                        color: isIboxActive ? "white" : "",
                      }}
                      onClick={handleToggleIbox}
                    >
                      {intl.formatMessage({ id: "ibox" })}
                    </td>
                    {/* <td
                      style={{
                        border: "2px solid grey",
                        width: "25%",
                        background: "green",
                        color: "white",
                      }}
                    >
                      {intl.formatMessage({ id: "bet" })}
                    </td> */}
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
